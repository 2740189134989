.size-full {
	@apply relative;

	img {
		@apply h-full w-full object-cover;
	}
}

.wp-block-heading mark {
	@apply bg-transparent;
	@apply text-steel-900;
}

.wp-block-columns.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

[type='checkbox']:checked,
[type='radio']:checked {
	@apply bg-blue-500;
}

[type='checkbox']:checked:hover,
[type='checkbox']:checked:focus,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
	@apply bg-steel-400;
}

.no-top {
	margin-block-start: -3rem !important;
}

.no-gap {
	gap: 0 !important;
}

body {
	font-family:
		'Source Sans 3',
		-apple-system,
		BlinkMacSystemFont,
		'Segoe UI',
		Roboto,
		Oxygen-Sans,
		Ubuntu,
		Cantarell,
		'Helvetica Neue',
		sans-serif;
}
