/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* purgecss end ignore */
@import 'tailwindcss/utilities';

@layer base {
	:root {
		--background: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--foreground: 188.6, 24.1%, 5.7%; /* --wp--preset--color--background #0b1112 */

		--muted: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--muted-foreground: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */

		--popover: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--popover-foreground: 188.6, 24.1%, 5.7%; /* --wp--preset--color--background #0b1112 */

		--card: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--card-foreground: 188.6, 24.1%, 5.7%; /* --wp--preset--color--background #0b1112 */

		--border: 177.5, 50%, 90.6%; /* --wp-preset-color--blue-1100 #dbf3f2 */
		--input: 177.5, 50%, 90.6%; /* --wp-preset-color--blue-1100 #dbf3f2 */

		--primary: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #111c1c */
		--primary-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--secondary: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--secondary-foreground: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #111c1c */

		--accent: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--accent-foreground: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #111c1c */

		--destructive: 7.8, 84.1%, 27.1%; /* --wp--preset--color--red-300 #7f1a0b */
		--destructive-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--ring: 180, 51.8%, 67.5%; /* --wp--preset--color--blue-900 #81d7d7 */

		--radius: 0.5rem;
	}

	.dark {
		--background: 188.6, 24.1%, 5.7%; /* --wp--preset--color--background #0b1112 */
		--foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--muted: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #1b4848 */
		--muted-foreground: 180, 51.8%, 67.5%; /* --wp--preset--color--blue-900 #81d7d7 */

		--popover: 188.6, 24.1%, 5.7%; /* --wp--preset--color--background #0b1112 */
		--popover-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--card: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #111c1c */
		--card-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--border: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */
		--input: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */

		--primary: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */
		--primary-foreground: 180, 24.4%, 8.8%; /* --wp--preset--color--blue-100 #111c1c */

		--secondary: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */
		--secondary-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--accent: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */
		--accent-foreground: 195, 7.3%, 78.4%; /* --wp--preset--color--grey-900 #c4cacc */

		--destructive: 7.3, 83.1%, 11.6%; /* --wp--preset--color--red-100 #360b05 */
		--destructive-foreground: 195, 6.7%, 88.2%; /* --wp--preset--color--grey-1000 #dfe2e3 */

		--ring: 180, 45.5%, 19.4%; /* --wp--preset--color--blue-300 #1b4848 */
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}
